import React from 'react';
import PropTypes from 'prop-types';

import picasso from 'picasso.js';

const labels = function labels({
  c,
  justify = 0,
  align = 0.5,
  position = 'outside',
  fontSize = 16,
  fill = '#111',
}) {
  return {
    type: 'labels',
    displayOrder: 2,
    settings: {
      sources: [{
        component: c,
        selector: 'path',
        strategy: {
          type: 'slice',
          settings: {
            direction: 'right',
            fontSize,
            align,
            labels: [{
              placements: [{
                position,
                fill,
                justify,
              }],
              label: function label(d) {
                return `${d.data.label} (${d.data.num.value} kr)`;
              },
            }],
          },
        },
      }],
    },
  };
};

class Piechart extends React.Component {
  constructor() {
    super();

    this.element = React.createRef();
  }

  state = {
    settings: {
      scales: {
        c: {
          data: { extract: { field: 'Namn' } }, type: 'color',
        },
      },
      components: [{
        type: 'legend-cat',
        scale: 'c',
      }, {
        key: 'p',
        type: 'pie',
        data: {
          extract: {
            field: 'Namn',
            props: {
              num: { field: 'Summa' },
            },
          },
        },
        settings: {
          slice: {
            arc: { ref: 'num' },
            fill: { scale: 'c' },
            outerRadius: () => 0.6,
            strokeWidth: 1,
            stroke: 'rgba(255, 255, 255, 0.5)',
          },
        },
      },
      labels({ c: 'p' }),
      ],
    },
  }

  componentDidMount() {
    const { data } = this.props;
    const { settings } = this.state;

    this.chart = picasso.chart({
      element: this.element.current,
      data: data || {},
      settings: settings || {},
    });
  }

  render() {
    const { data } = this.props;
    const { settings } = this.state;

    if (this.chart) {
      this.chart.update({
        data: (this.props && data) || {},
        settings: settings || {},
      });
    }

    return (
      <div>
        <div
          ref={this.element}
          className="rendering-area"
          style={{ height: 300, width: '100%', position: 'relative' }}
        />
      </div>
    );
  }
}

Piechart.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string.isRequired,
    data: PropTypes.array,
  }).isRequired,
};

export default Piechart;
