const pBB = 46500;

// All Calculations done here
export default class Calc {
  static getAlder(fodelsear) {
    const year = new Date().getFullYear();
    const alder = year - fodelsear;
    return alder;
  }

  static getKyrkoSkatt(kyrkoavgiften, salary, kiIn, alder) {
    let ki = kiIn;
    const kyrkoavgift = kyrkoavgiften;
    // räkna ut skatt innan kyrkoavgiften är pålagd
    const skattUk = Calc.getSkatt(salary, ki, pBB, alder);
    // lägger på kyrkoprocenten
    ki += kyrkoavgift;
    // räknar ut skatten igen med kyrkoprocent på
    const skattMk = Calc.getSkatt(salary, ki, pBB, alder);
    // (skatt+kyrkoskatt) - skatt
    const kyrkoskatt = skattMk - skattUk;
    return kyrkoskatt;
  }

  // Calc grundavdrag
  static getGrundAvdrag(monthpay, customPBB) {
    let grundAvdrag;
    const gaCalc = monthpay * 12 / customPBB;
    const yearSaluary = monthpay * 12;

    if (gaCalc <= 0.99) {
      grundAvdrag = (0.423 * customPBB);
    } else if (gaCalc > 0.99 && gaCalc <= 2.72) {
      grundAvdrag = 0.423 * customPBB + 0.2 * (yearSaluary - 0.99 * customPBB);
    } else if (gaCalc > 2.72 && gaCalc <= 3.11) {
      grundAvdrag = (0.77 * customPBB);
    } else if (gaCalc > 3.11 && gaCalc <= 7.88) {
      grundAvdrag = 0.77 * customPBB - 0.1 * (yearSaluary - (3.11 * customPBB));
    } else if (gaCalc > 7.88) {
      grundAvdrag = (0.293 * customPBB);
    }

    return Math.floor(grundAvdrag);
  }

  // För personer som är 65 år eller äldre.

  static getExtraGrundAvdrag(monthpay, customPBB) {
    let grundAvdragplus;
    const gaCalc = monthpay * 12 / customPBB;
    const yearSaluary = monthpay * 12;
    if (gaCalc <= 0.99) {
      grundAvdragplus = 0.687 * customPBB;
    } else if (gaCalc > 0.99 && gaCalc <= 1.11) {
      grundAvdragplus = 0.885 * customPBB - 0.2 * yearSaluary;
    } else if (gaCalc > 1.11 && gaCalc <= 2.72) {
      grundAvdragplus = 0.6 * customPBB + 0.057 * yearSaluary;
    } else if (gaCalc > 2.72 && gaCalc <= 3.11) {
      grundAvdragplus = 0.34 * yearSaluary - 0.169 * customPBB;
    } else if (gaCalc > 3.11 && gaCalc <= 3.21) {
      grundAvdragplus = 0.44 * yearSaluary - 0.48 * customPBB;
    } else if (gaCalc > 3.21 && gaCalc <= 4.45) {
      grundAvdragplus = 0.207 * customPBB + 0.228 * yearSaluary;
    } else if (gaCalc > 4.45 && gaCalc <= 5.31) {
      grundAvdragplus = 1.397 * customPBB - 0.039 * yearSaluary;
    } else if (gaCalc > 5.31 && gaCalc <= 7.88) {
      grundAvdragplus = 0.763 * customPBB + 0.08 * yearSaluary;
    } else if (gaCalc > 7.88 && gaCalc <= 8.08) {
      grundAvdragplus = 1.551 * customPBB - 0.02 * yearSaluary;
    } else if (gaCalc > 8.08 && gaCalc <= 13.54) {
      grundAvdragplus = 2.399 * customPBB - 0.125 * yearSaluary;
    } else if (gaCalc > 13.54 && gaCalc < 34) {
      grundAvdragplus = 1.031 * customPBB - 0.024 * yearSaluary;
    } else if (gaCalc >= 34) {
      grundAvdragplus = 0.215 * yearSaluary;
    }

    return Math.floor(grundAvdragplus);
  }


  /* Vill man speca den allmänna Pensionsavgiften kan man hämta ut denna här. Den är
  inkluderad i kommunalskatten. Denna betalas av alla som är födda efter 1937 */

  static getPensionsAvgift(monthpay) {
    const ibb = 64400; // inkomstbasbelopp för 2019
    const yearSaluary = monthpay * 12;
    let getPensionsAvgift = 0;

    if (yearSaluary < 0.423 * ibb) {
      getPensionsAvgift = 0;
    } else if (yearSaluary > 0.423 * ibb && yearSaluary < 8.07 * ibb) {
      getPensionsAvgift = 0.07 * yearSaluary;
    } else if (yearSaluary > 8.07 * ibb) {
      getPensionsAvgift = 0.07 * (8.07 * ibb);
    }

    return Math.floor(getPensionsAvgift);
  }

  // Calc jobbskatteavdraget
  static getJobbSkatteavdraget(monthpay, customPBB, kommun) {
    const jAcalc = monthpay * 12 / customPBB;
    const yearS = monthpay * 12;
    const grundAvdrag = Calc.getGrundAvdrag(monthpay, customPBB);
    let avdrag = 0;

    if (jAcalc <= 0.91) {
      avdrag = (yearS - grundAvdrag) * kommun;
    } else if (jAcalc > 0.91 && jAcalc <= 3.24) {
      // korrekt
      avdrag = ((0.91 * customPBB + 0.3405 * (yearS - 0.91 * customPBB)) - grundAvdrag) * kommun;
    } else if (jAcalc > 3.24 && jAcalc <= 8.08) {
      // Korrekt
      avdrag = ((1.703 * customPBB + 0.128 * (yearS - 3.24 * customPBB)) - grundAvdrag) * kommun;
    } else if (jAcalc > 8.08 && jAcalc <= 13.54) {
      // Korrekt
      avdrag = (2.323 * customPBB - grundAvdrag) * 0.315;
    } else if (jAcalc > 13.54) {
      // Korrekt
      avdrag = ((2.323 * customPBB - grundAvdrag) * kommun) - (0.03 * (13.54 * customPBB));
    }

    return Math.floor(avdrag);
  }

  // Calc jobbskatteavdraget
  static getJobbSkatteavdragetGammal(monthpay) {
    const yearS = monthpay * 12;
    let avdrag = 0;

    if (yearS < 100000) {
      avdrag = 0.2 * yearS;
    } else if (yearS >= 100000 && yearS < 300000) {
      avdrag = 15000 + (0.05 * yearS);
    } else if (yearS >= 300000 && yearS < 600000) {
      avdrag = 30000;
    } else if (yearS <= 600000) {
      avdrag = 30000 - (0.03 * (yearS - 600000));
    }

    return Math.floor(avdrag);
  }

  // Calc statlig skatt & värnskatt
  static getStatligSkatt(monthpay) {
    const skiftgrans1 = 490700;
    const skiftgrans2 = 689300;
    const grundAvdrag = Calc.getGrundAvdrag(monthpay, pBB) / 12;
    let skikt1Skatt = 0;
    let skikt2Skatt = 0;
    const skikt1 = monthpay - (monthpay - skiftgrans1 / 12);
    const skikt2 = monthpay - (monthpay - skiftgrans2 / 12);

    if (monthpay >= skiftgrans2 / 12) {
      skikt1Skatt = (monthpay - grundAvdrag - skikt1) * 0.20;
      skikt2Skatt = (monthpay - grundAvdrag - skikt2) * 0.05;
    } else if (monthpay >= skiftgrans1 / 12) {
      skikt1Skatt = (monthpay - grundAvdrag - skikt1) * 0.2;
    } else if (monthpay < skiftgrans1 / 12) {
      skikt1Skatt = 0;
      skikt2Skatt = 0;
    }
    return {
      statligskatt: Math.floor(skikt1Skatt),
      varnSkatt: Math.floor(skikt2Skatt),
    };
  }

  // Calc skatt
  static getSkatt(monthpay, ki, customPBB, alder) {
    let skatt = 0;
    const yearsOld = Calc.getAlder(alder);

    if (yearsOld < 65) {
      const jobbSkatteAvdrag = Calc.getJobbSkatteavdraget(monthpay, customPBB, ki) / 12;
      const statligSkatt = Calc.getStatligSkatt(monthpay);
      const grundAvdrag = Calc.getGrundAvdrag(monthpay, customPBB) / 12;
      skatt = -jobbSkatteAvdrag
        + (monthpay - grundAvdrag) * ki + statligSkatt.statligskatt + statligSkatt.varnSkatt;
    } else if (yearsOld > 65) {
      const jobbSkatteAvdragGammal = Calc.getJobbSkatteavdragetGammal(monthpay) / 12;
      const statligSkatt = Calc.getStatligSkatt(monthpay);
      const grundAvdrag = Calc.getGrundAvdrag(monthpay, customPBB) / 12;
      const extraGrundAvdrag = Calc.getExtraGrundAvdrag(monthpay, customPBB) / 12;
      const grundAvdragTotal = grundAvdrag + extraGrundAvdrag;
      skatt = -jobbSkatteAvdragGammal + (monthpay - grundAvdragTotal) * ki
       + statligSkatt.statligskatt
       + statligSkatt.varnSkatt;
    }

    return Math.floor(skatt);
  }
}
