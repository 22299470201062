import React from 'react';

import Layout from '../../layout';

function Index() {
  return (
    <Layout>
      <h1>Välkommen till räkna.online!</h1>
      <p>Vi lägger upp lite olika sätt att räkna ut avancerade grejer som skall hjälpa er. Om det är nåt ni önskar så bara maila till <a href="mailto:infp@webb46.se">info@webb46.se</a>.<br /> Ha de gött!</p>
    </Layout>
  );
}

export default Index;
