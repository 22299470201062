import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Index from './pages/index/index';

import About from './pages/about';

import Salary from './pages/salary';

import Rantaparanta from './pages/ranta-pa-ranta';

function App() {
  return (
    <Router>
      <Route exact path="/" component={Index} />
      <Route path="/about" component={About} />
      <Route path="/lon-efter-skatt" component={Salary} />
      <Route path="/ranta-pa-ranta" component={Rantaparanta} />
    </Router>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
