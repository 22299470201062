import React from 'react';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  appBar: {
    flexDirection: 'row',
    backgroundColor: '#bbd5c7',
    color: '#40364d',
    padding: '6px 0', 
  },
  logoText: {
    fontSize: '18px',
    marginLeft: '8px',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
});

function TopNav({
  classes,
  menuItems,
  onHamburgerClick,
}) {
  return (
    <div className="header">
      <AppBar className={classes.appBar}>
        <Container maxWidth="lg">
          <Box
            display="flex"
            alignItems="center"
          >
            <Link
              color="inherit"
              noWrap
              href="/"
              className="flex-row-center"
            >
              <img src="/img/calc.svg" alt="" height="40" />
              <span className={classes.logoText}>Räkna.online</span>
            </Link>
            <Box flexGrow="1" />
            <Hidden xsDown>
              {menuItems.map(section => (
                <Link
                  color="inherit"
                  noWrap
                  key={section.href}
                  variant="body2"
                  href={`${section.href}`}
                  className={classes.toolbarLink}
                >
                  {section.name}
                </Link>
              ))}
            </Hidden>
            <Hidden smUp>
              <IconButton
                color="inherit"
                className={classes.toolbarLink}
                onClick={onHamburgerClick}
              >
                <SvgIcon viewBox="0 0 24 24">
                  <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
                </SvgIcon>
              </IconButton>
            </Hidden>
          </Box>
        </Container>
      </AppBar>
    </div>
  );
}

TopNav.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    href: PropTypes.string,
  })).isRequired,
  onHamburgerClick: PropTypes.func,
};

TopNav.defaultProps = {
  onHamburgerClick: () => {},
};

export default withStyles(styles)(TopNav);
