import React from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';

import SideNav from '../components/sidenav';
import TopNav from '../components/topnav';

const styles = theme => ({
  container: {
    paddingTop: '52px',
  },
  cardGrid: {
    marginTop: theme.spacing(3),
  },
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  sidebarAboutBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
  coverSubtitle: {
    marginBottom: 0,
  },
});

const sections = [
  { name: 'Lön efter skatt', href: '/lon-efter-skatt' },
  { name: 'Ränta på ränta', href: '/ranta-pa-ranta' },
  { name: 'Data från fonder', href: 'http://fond-data.se' },
];

const featuredPosts = [
  {
    title: 'Räkna ut lön efter skatt',
    description:
    'Ta reda på hur mycket du får ut efter skatt, samt vad skatten består av och var din lön ligger jämfört med andras löner i Sverige',
    href: '/lon-efter-skatt',
  },
  {
    title: 'Ränta på ränta kalkylator',
    description:
    'Se hur stor din portfölj blir efter ett visst antal år med vår ränta-på-ränta kalkylator',
    href: '/ranta-pa-ranta',
  },
  {
    title: 'Se data från fonder',
    description:
    'Se data över olika fonders innehav',
    href: 'http://fond-data.se',
  },
];

class MainLayout extends React.Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    sidebarItems: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
  };

  static defaultProps = {
    sidebarItems: null,
    children: null,
  };

  state = {
    sideNavOpen: false,
  };

  toggleSideNav = () => {
    this.setState(prevState => ({
      sideNavOpen: !prevState.sideNavOpen,
    }));
  };

  closeSideNav = () => {
    this.setState({
      sideNavOpen: false,
    });
  }

  render() {
    const {
      classes,
      sidebarItems,
      children,
    } = this.props;

    const {
      sideNavOpen,
    } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <SideNav isOpen={sideNavOpen} menuItems={sections} onClose={this.closeSideNav} />
        <TopNav menuItems={sections} onHamburgerClick={this.toggleSideNav} />
        <Container maxWidth="lg" className={classes.container}>
          <main>
            <Grid container spacing={5}>
              {/* Main content */}
              <Grid item xs={12} md={7}>
                {children}
              </Grid>
              {/* End main content */}

              {/* Sidebar */}
              <Grid item xs={12} md={5}>
                {sidebarItems}
                <br />
                <Paper elevation={0} className={classes.sidebarAboutBox}>
                  <Typography variant="h6" gutterBottom>
                    Om oss
                  </Typography>
                  <Typography>
                    räkna.online är framtaget för att värna om en sund privatekonomi
                  </Typography>
                </Paper>
              </Grid>
              {/* End sidebar */}
            </Grid>

            {/* Sub featured posts */}
            <Grid container spacing={4} className={classes.cardGrid}>
              {featuredPosts.map((post, i) => (
                <Hidden smDown={i !== 0} key={post.href}>
                  <Grid item xs={12} md={6}>
                    <CardActionArea component="a" href={post.href}>
                      <Card className={classes.card}>
                        <div className={classes.cardDetails}>
                          <CardContent>
                            <Typography component="h2" variant="h5">
                              {post.title}
                            </Typography>
                            <Typography variant="subtitle1" paragraph className={classes.coverSubtitle}>
                              {post.description}
                            </Typography>
                          </CardContent>
                        </div>
                      </Card>
                    </CardActionArea>
                  </Grid>
                </Hidden>
              ))}
            </Grid>
            {/* End sub featured posts */}
          </main>
        </Container>

        {/* Footer */}
        <footer className={classes.footer}>
          <Container maxWidth="lg">
            <Typography variant="h6" align="center" gutterBottom>
              räkna.online
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
              räkna.online är skapad för att främja en sund privatekonomi
            </Typography>
          </Container>
        </footer>
        {/* End footer */}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(MainLayout);
