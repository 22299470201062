import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Layout from '../../layout';
import Calc from './calc';
import Piechart from './piechart';

const pBB = 46500;

class Salary extends React.Component {
  constructor() {
    super();
    this.fetchData();
  }

  state = {
    alder: '1985',
    salary: 25000,
    kommuner: [],
    kyrkmedlem: false,
    kommun: [],
    kyrkId: '',
    kommunId: '',
    calcResults: {
      statligSkatt: {},
    },
  }

  changeKommun = async (e) => {
    const v = e.target.value;

    const kommun = await fetch(`data/${v}.json`)
      .then(response => response.json());

    this.setState({
      kommunId: v,
      kommun,
      kyrka: kommun.forsamlingar[0],
    }, this.calculate);
  }

  changeKyrka = async (e) => {
    const v = e.target.value;
    const { kommun } = this.state;
    const kyrka = kommun.forsamlingar.filter(obj => obj.kod === v)[0];
    this.setState({ kyrka, kyrkId: v }, this.calculate);
  }

  calculate = async (e) => {
    if (e) {
      e.preventDefault();
    }

    const {
      alder, salary, kommun, kyrka, kyrkmedlem,
    } = this.state;

    // When Calculating
    if (salary === '') {
      return;
    }

    let ki = kommun.skattesats / 100;
    let kyrkoavgift = 0;

    if (kyrkmedlem === true) {
      kyrkoavgift = kyrka.avgiftssatsKyrkoavgift / 100;
      ki += kyrkoavgift;
    }

    const skatteTabell = Math.round(ki * 100);
    const skatt = Calc.getSkatt(salary, ki, pBB, alder);
    const grundAvdrag = Math.round(Calc.getGrundAvdrag(salary, pBB) / 12);
    const jobbSkatteAvdrag = Math.round(Calc.getJobbSkatteavdraget(salary, pBB, ki) / 12);
    const lonEfterSkatt = salary - skatt;

    const result = {
      kommunNamn: kommun.namn,
      skatteTabell,
      grundAvdrag,
      statligSkatt: Calc.getStatligSkatt(salary),
      jobbSkatteAvdrag,
      kyrkoavgift: kyrkmedlem === true ? Calc.getKyrkoSkatt(kyrkoavgift, salary, ki, alder) : 0,
      skatt,
      lonEfterSkatt,
    };

    this.setState({
      calcResults: result,
    });
  }

  async fetchData() {
    // Hämta json-datan.
    const kommuner = await fetch('data/kommun.json')
      // Vänta på response
      .then(response => response.json());
      // retunera resonse till jsoni consten = kommuner
    this.setState({ kommuner });
  }


  render() {
    const spanStyle = {
      fontWeight: 'bold',
    };
    const spanStyleUnderline = {
      textDecoration: 'underline',
    };
    const {
      alder, salary, kommuner, calcResults, kyrkmedlem, kommun, kommunId, kyrkId,
    } = this.state;

    const sidebarItems = [];

    if (calcResults.skatt > 0) {
      sidebarItems.push(<Piechart
        key="graf"
        data={{
          type: 'matrix',
          data: [
            ['Namn', 'Summa'],
            ['Skatt', calcResults.skatt - calcResults.statligSkatt.statligskatt - calcResults.kyrkoavgift],
            ['Statlig skatt', calcResults.statligSkatt.statligskatt],
            ['Värnskatt', calcResults.statligSkatt.varnSkatt],
            ['Kyrkoskatt', calcResults.kyrkoavgift],
            ['Behållning', calcResults.lonEfterSkatt],
          ],
        }}
      />);
    }

    return (
      <Layout sidebarItems={sidebarItems}>
        <div className="container mt-5">
          <h1 className="text-center display-5">
            Beräkna lön efter skatt
          </h1>
          <form id="form" className="mt-5">
            <div className="form-group">
              <TextField
                id="fodelsear"
                label="Vilket år är du född?"
                style={{ minWidth: '100%' }}
                value={alder}
                onChange={v => this.setState({ alder: v.target.value }, this.calculate)}
                margin="normal"
              />
            </div>
            <br />
            <div className="form-group">
              <FormControl style={{ minWidth: '100%' }}>
                <InputLabel htmlFor="incomeyear-simple">Inkomstår</InputLabel>
                <Select
                  value={2019}
                  // onChange={handleChange}
                  inputProps={{
                    name: 'incomeyear',
                    id: 'incomeyear-simple',
                  }}
                >
                  <MenuItem value={2019}>2019</MenuItem>
                  {/* <MenuItem value={2018}>2018</MenuItem> */}
                </Select>
              </FormControl>
            </div>
            <br />
            <div className="form-group">
              <TextField
                id="manadslon-name"
                label="Månadslön före skatt"
                style={{ minWidth: '100%' }}
                value={salary}
                onChange={v => this.setState({ salary: v.target.value }, this.calculate)}
                margin="normal"
                InputProps={{
                  endAdornment: <InputAdornment position="end">kr/mån</InputAdornment>,
                }}
              />
            </div>
            <br />
            <div className="form-group">
              <FormControl style={{ minWidth: '100%' }}>
                <InputLabel htmlFor="kommun-simple">Vilken kommun bor du i?</InputLabel>
                <Select
                  value={kommunId}
                  onChange={this.changeKommun}
                  inputProps={{
                    name: 'kommun',
                    id: 'kommun-simple',
                  }}
                >
                  {kommuner.map(item => (
                    <MenuItem key={item.kod} value={item.kod}>{item.namn}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <br />
            <div className="form-group">
              {calcResults.skatt ? (
                <div className="form-check">
                  <FormControlLabel
                    control={(
                      <Checkbox
                        className="form-check-input"
                        onChange={() => this.setState({ kyrkmedlem: !kyrkmedlem }, this.calculate)}
                        value={kyrkmedlem}
                        color="primary"
                        name="church"
                        id="church"
                      />
                    )}
                    label="Medlem i svenska kyrkan?"
                  />
                </div>
              ) : null }
            </div>
            {kyrkmedlem ? (
              <div className="form-group forsamlings-group">
                <FormControl style={{ minWidth: '100%' }}>
                  <InputLabel htmlFor="forsamling">Vilken församling bor du i?</InputLabel>
                  <Select
                    value={kyrkId}
                    onChange={this.changeKyrka}
                    inputProps={{
                      name: '',
                      id: 'forsamling',
                    }}
                  >
                    {kommun.forsamlingar.map(forsamlingar => (
                      <MenuItem
                        key={forsamlingar.kod}
                        value={forsamlingar.kod}
                      >
                        {forsamlingar.namn}

                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ) : null }
          </form>
          <div id="calculations">
            {calcResults.skatt ? (
              <div className="resultat">
                <h2 className="sammanfattning-titel">
                  Sammanfattning:
                </h2>
                <p className="sammanfattning-text">
                  Du bor i
                  {' '}
                  {calcResults.kommunNamn}
                  {' '}
                  och du följer skattetabellen:
                  {' '}
                  <span style={spanStyle}>
                    {calcResults.skatteTabell}
                  </span>
                  .
                  {' '}
                  <span style={spanStyleUnderline}>
                      Efter skatt kommer du få ut
                    {' '}
                    <span style={spanStyle}>
                      {calcResults.lonEfterSkatt}
                    </span>
                    {' '}
                    kronor
                  </span>
                </p>
                <hr />
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Kategori</TableCell>
                        <TableCell align="right">kr</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {calcResults.kyrkoavgift > 0 ? (
                        <TableRow>
                          <TableCell>Kyrkoskatt</TableCell>
                          <TableCell align="right">{calcResults.kyrkoavgift}</TableCell>
                        </TableRow>
                      ) : null }
                      <TableRow>
                        <TableCell>Skatt (Inklusive Grundavdrag & Jobbskatteavdrag)</TableCell>
                        <TableCell align="right">{calcResults.skatt - calcResults.statligSkatt.statligskatt - calcResults.kyrkoavgift}</TableCell>
                      </TableRow>
                      {calcResults.statligSkatt.statligskatt > 0 ? (
                        <TableRow>
                          <TableCell>Statlig skatt</TableCell>
                          <TableCell align="right">{calcResults.statligSkatt.statligskatt}</TableCell>
                        </TableRow>
                      ) : null }
                      {calcResults.statligSkatt.varnSkatt > 0 ? (
                        <TableRow>
                          <TableCell>Värnskatt</TableCell>
                          <TableCell align="right">{calcResults.statligSkatt.varnSkatt}</TableCell>
                        </TableRow>
                      ) : null }
                      {/* <TableRow>
                        <TableCell>grundavdrag</TableCell>
                        <TableCell align="right">
                          +
                          {Math.floor(calcResults.grundAvdrag / 12)}
                          {' '}
                          kr
                        </TableCell>
                      </TableRow> */}
                      {/* <TableRow>
                        <TableCell>jobbskatteavdrag</TableCell>
                        <TableCell align="right">
                          +
                          {Math.floor(calcResults.jobbSkatteAvdrag / 12)}
                          {' '}
                          kr
                        </TableCell>
                      </TableRow> */}
                    </TableBody>
                  </Table>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Layout>
    );
  }
}

export default Salary;
