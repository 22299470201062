import React from 'react';

import Layout from '../../layout';

import Calculator from './calculator';

function RantaPaRanta() {
  return (
    <Layout>
      <h1>Ränta på ränta-kalkylator</h1>
      <Calculator />
    </Layout>
  );
}

export default RantaPaRanta;
