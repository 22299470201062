import React, { useState } from 'react';
import ReactSlider from 'react-slider';

const calcPortfolio = ({ percentage, sekPerMonth, numberOfYears }) => {
  let portfolioValue = 0;
  const items = [];
  for (let index = 0; index < (numberOfYears); index += 1) {
    const calculatedValue = Math.floor(
      Math.floor(portfolioValue + (sekPerMonth * 12)) * (1 + (percentage / 100)),
    );
    items.push({
      startValue: portfolioValue,
      endValue: calculatedValue,
      percentChange: (percentage / 100),
    });
    portfolioValue = calculatedValue;
  }
  return portfolioValue.toFixed();
};

const formatSEK = (number) => new Intl.NumberFormat('sv-SE', {
  style: 'currency', currency: 'SEK', maximumFractionDigits: 0, minimumFractionDigits: 0,
}).format(Number(number).toFixed());

const Calculator = () => {
  const [percentage, setPercentage] = useState(9.99);
  const [sekPerMonth, setSekPerMonth] = useState(1000);
  const [numberOfYears, setNumberOfYears] = useState(18);

  return (
    <div>
      <h3 style={{ fontSize: '2rem', marginBottom: '0.2rem' }} className="has-text-weight-bold">
        {formatSEK(calcPortfolio({ percentage, sekPerMonth, numberOfYears }))}
      </h3>
      <div style={{ marginBottom: '1.5rem', fontSize: '1rem' }}>Portföljvärde baserat på</div>

      <h4 style={{ fontSize: '1.5rem', marginBottom: '0.2rem' }}>Årsavkastning</h4>
      <ReactSlider
        ariaLabelledby="slider-label"
        className="horizontal-slider"
        thumbClassName="slider-thumb"
        trackClassName="slider-track"
        step={0.01}
        min={0.5}
        max={25}
        onChange={(v) => setPercentage(v)}
        defaultValue={percentage}
        renderThumb={(props, state) => (
          <div {...props}>
            {Number(state.valueNow).toFixed(2)}
            %
          </div>
        )}
      />

      <h4 style={{ fontSize: '1.5rem', marginBottom: '0.2rem' }}>Sparande per månad</h4>
      <ReactSlider
        ariaLabelledby="slider-label"
        className="horizontal-slider"
        thumbClassName="slider-thumb"
        trackClassName="slider-track"
        step={100}
        min={100}
        max={20000}
        onChange={(v) => setSekPerMonth(v)}
        defaultValue={sekPerMonth}
        renderThumb={(props, state) => (
          <div {...props}>
            {state.valueNow}
            {' '}
            kr
          </div>
        )}
      />

    <h4 style={{ fontSize: '1.5rem', marginBottom: '0.2rem' }}>Antal år</h4>
      <ReactSlider
        ariaLabelledby="slider-label"
        className="horizontal-slider"
        thumbClassName="slider-thumb"
        trackClassName="slider-track"
        step={1}
        min={2}
        max={100}
        onChange={(v) => setNumberOfYears(v)}
        defaultValue={numberOfYears}
        renderThumb={(props, state) => (
          <div {...props}>
            {state.valueNow}
            {' '}
            år
          </div>
        )}
      />
    </div>
  );
};

export default Calculator;
