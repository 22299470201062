import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  paper: {
    backgroundColor: '#bbd5c7',
    color: '#40364d',
  },
  logo: {
    padding: '16px',
  },
  logoText: {
    fontSize: '18px',
    marginLeft: '8px',
  },
});

const SideNav = ({
  classes,
  isOpen,
  menuItems,
  onClose,
}) => (
  <Drawer
    anchor="right"
    open={isOpen}
    onClose={onClose}
    classes={{
      paper: classes.paper,
    }}
  >
    <Link
      color="inherit"
      className={`${classes.logo} flex-row-center`}
      href="/"
    >
      <img src="/img/calc.svg" alt="" width="36" />
      <span className={classes.logoText}>Räkna.online</span>
    </Link>

    <List>
      {
        menuItems.map(item => (
          <Fragment key={item.name}>
            <Link href={item.href} color="inherit">
              <ListItem>
                <ListItemText primary={item.name} />
              </ListItem>
            </Link>

            <Divider />
          </Fragment>
        ))
      }
    </List>
  </Drawer>
);

SideNav.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    href: PropTypes.string,
  })).isRequired,
  onClose: PropTypes.func,
};

SideNav.defaultProps = {
  onClose: () => {},
};

export default withStyles(styles)(SideNav);
